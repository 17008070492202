#index {
    #wrapper.l-wrapper,
    .elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 100%;
    }
}
.elementor {
    .elementor-widget-product-carousel {
        margin: 0 auto;
    }
    
    
    .elementor-widget-image .elementor-image figure > a, .elementor-widget-image .elementor-image > a {
        display: block;
    }
    .elementor-section-boxed {
        .elementor-column {
            img {
                width: 100%;
            }
        }    
    }
    .ce-caption {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        a {
            width: 100%;
            img {
                opacity: .2;
                transition: $transition;
            }
            &:hover {
                img {
                    opacity: .7;
                }
            }
        }
        .widget-image-caption {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            text-align: center;
            font-family: $headings-font-family;
            color: $white;
            font-size: 140%;
            @media (min-width:1920px){
                font-size: $h1-font-size;
            }
        }
    }
}

#carousel {
//    background-color: $secondary;
    margin-bottom: -70px;
    .slick-dots {
        bottom: 70px;
        position: relative; 
        button {
            background: $white !important;   
        }
        .slick-active {
            button {
                background: $primary !important;
            }
        }
    }
    
    &.slick__arrow-large {
        .left, .right, button.slick-arrow {
            display: block;
            width: 50px;
            height: 50px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            z-index: 1; 
            & > i {
                /*
                font-size: 2*$font-size-base;
                line-height: 1;
                border-radius: 50%;
                text-indent: -9999px;
                */
                visibility: hidden;
            }
            
            @media (max-width: 767px){
                width: 20px;
                height: 20px; 
            }@media (min-width: 768px)and(max-width:991px){
                width: 25px;
                height: 25px; 
            }@media (min-width: 992px)and(max-width:1199px){
                width: 30px;
                height: 30px;    
            }
        }.left, .slick-prev {
            background: url(../img/left-arrow-white.svg) no-repeat center center transparent;
            background-size: cover; 
            left: 20px
        }.right, .slick-next {
            background: url(../img/right-arrow-white.svg) no-repeat center center transparent;
            background-size: cover;
            right: 20px;
        }
        /*
        .material-icons {
            font-family: 'icomoon';
            &:before {
                font-size: 83%;
            }
        }
        .left:before {
            content: "\e907";    
        }.right:before {
            content: "\e908";    
        }*/
    }

}

.carousel {
    .slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
    }    
}

.brands_list {
    .brands-item {
    }   
    .brands-link {
    }
    .slick-track {
        display: flex;
        align-items: center;
    }
    &.slick-arrow, &.slick-arrow .slick-arrow {
        max-height: 130px;
    }
    @media(max-width: 767px){
        .slick-slide {
            margin: 0 2rem;        
        }
    }
    @media (min-width: 768px){
        .brands-img {
            max-height: 100px;
        }
    }
}