.blockreassurance {
    .block-icon {
        margin: 2rem auto;
        &:before {
            font-size: 300%;
        }   
    }
    .block-title {
        color: $white;
        font-size: 26px;
        font-family: $headings-font-family;
        font-weight: 500 !important;
    }  
    @media (max-width: 575px){
        text-align: center !important;
        .block-title {
            font-size: 17px;
        }
        .block-icon {
            display: block !important;
            float: none !important;
            margin: 0 auto !important;
            &:before {
                font-size: 150%;
            }

        }
    }@media (min-width: 576px)and(max-width: 767px){
        .block-title {
            font-size: 19px;
        }
        .block-icon {
            margin: 1rem auto;
            &:before {
                font-size: 240%;
            }

        }
    }@media (min-width: 768px)and(max-width: 1199px){
        .block-title {
            font-size: 20px;
        }
        .block-icon {
            margin: 1.5rem auto;
            &:before {
                font-size: 240%;
            }

        }
    }
}

.footer_top {
    color: $white;
    a {
        color: $white;
    }
    .row {
        align-content: center;
        align-items: stretch;
    }
    &_item {
        padding: 2rem;
        align-self: center;
        order: 2;
    }
    &_france {
        height: 148px;
        width: auto;
        display: block;
        margin: 1rem auto;
    }
    &_logo {
        height: 210px;
        width: auto;
    }
    
    &_item1 {
        order: 2;
    }&_item2 {
        order: 3;
    }.social-sharing {
        order: 4;
    }.blockreassurance {
        order: 1;
    }
} 

.l-footer {
  color: $secondary;
  .cms-page-link,
  .custom-page-link,
  .account-list a{
      color: $secondary;
    &:hover{
      color: theme-color('primary');

    }
  }
}
.footer-container{
  margin-top: $footer-container-margin-top;
}

.footer__title{
  /*color:$black;
  &:visited,&:hover{
    color: $black;
  }*/
  text-transform: uppercase;
  display: block;
  font-weight: 700;
  margin-bottom: $spacer;
  &--mobile{
    display: none;
    margin-bottom: 0;
    padding: $spacer/2 0;
    border-bottom: 1px solid $border-color;
    

    &:after{
      content:"\e313";
      font-family: 'Material Icons';
      font-feature-settings: 'liga' 1;
      position: absolute;
      right: $grid-gutter-width/2;
      transition: .3s transform ease-in-out;
      font-size: $font-size-base *1.5;
    }
    &[data-toggle="collapse"][aria-expanded="true"]:after {
      transform:         rotate(180deg);
    }
  }
}

.footer_paiement {
    .img-fluid {
        width: 128px;
    }
    @media (max-width: 767px){
        margin-top: 1rem;
        &.text-center {
            text-align: left !important;
        }
        .footer_paiement_icones {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            flex-wrap: wrap;
        }
    }
}

@include media-breakpoint-mobile {
  .footer__title{
    &--desktop{

      display: none;
    }
    &--mobile{
      display: block;
    }
  }
}
.footer__copyright{
  text-align: center;
  font-size: $font-size-sm;
}
.header__nav{
  align-items: center;
}
.header-nav__nav2{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-mobile{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-mobile__user-cart{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__top{
  display: flex;
  justify-content: space-between;
  position: static;
}
