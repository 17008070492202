.search-widget{
  //max-width: 450px;
  margin: 0 auto;
}
.search-widget__group{
  position: relative;
}
.search-widget__input-right{
  //padding-right: 50px ;
}
.search-widget__btn{
    position: absolute;
    right: 0;
    top: 0;
    background: transparent;
    border: 0;
    .icon-Magnifier {
        font-size: 170%;
        color: $secondary;
        line-height: 2.7rem;
    }
}
@include media-breakpoint-mobile{
    .search-widget__label {
//        display: none;
        font-size: 13px;
    }
  .search-widget{
    max-width: unset;
  }
}
