/* nunito-300 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/nunito-v24-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v24-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v24-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v24-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v24-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v24-latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
}
/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/nunito-v24-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v24-latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-600 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/nunito-v24-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v24-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v24-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v24-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v24-latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/nunito-v24-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v24-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v24-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v24-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v24-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v24-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* nunito-italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/nunito-v24-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/nunito-v24-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/nunito-v24-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/nunito-v24-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/nunito-v24-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/nunito-v24-latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
}

/* raleway-500 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/raleway-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/raleway-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v27-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v27-latin-500.svg#Raleway') format('svg'); /* Legacy iOS */
}
/* raleway-700 - latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/raleway-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/raleway-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/raleway-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/raleway-v27-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/raleway-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/raleway-v27-latin-700.svg#Raleway') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?qd3dmq');
  src:  url('../fonts/icomoon.eot?qd3dmq#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?qd3dmq') format('truetype'),
    url('../fonts/icomoon.woff?qd3dmq') format('woff'),
    url('../fonts/icomoon.svg?qd3dmq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Backup:before {
  content: "\e900";
}
.icon-basket:before {
  content: "\e901";
}
.icon-chevron-arrow:before {
  content: "\e902";
}
.icon-down-arrow:before {
  content: "\e903";
}
.icon-euro-coin:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-instagram:before {
  content: "\e906";
}
.icon-left-arrow:before {
  content: "\e907";
}
.icon-right-arrow:before {
  content: "\e908";
}
.icon-love:before {
  content: "\e909";
}
.icon-Magnifier:before {
  content: "\e90a";
}
.icon-message:before {
  content: "\e90b";
}
.icon-profile:before {
  content: "\e90c";
}
.icon-Reload:before {
  content: "\e90d";
}
.icon-Plus:before {
  content: "\e90e";
}
.icon-Remove:before {
  content: "\e90f";
}
.icon-shield-check:before {
  content: "\e910";
}
.icon-shipping-truck:before {
  content: "\e911";
}
.icon-shop:before {
  content: "\e912";
}



@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
  url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: $font-size-base*$line-height-base;  /* Preferred icon size */
  display: inline-block;
  vertical-align: top;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
//fix height
.material-icons + span{
  display: inline-block;
}
.small .material-icons,
.btn-sm .material-icons{
  font-size: $small-font-size;
  line-height: $line-height-base;
}
.btn-lg .material-icons{
  font-size: $font-size-lg*$btn-line-height-lg;
}

.pagination .material-icons{
  font-size: $font-size-base*$pagination-line-height;
}
.material-icons.md-18,
{ font-size: 18px; line-height: 1}
.material-icons.md-24,
{ font-size: 24px; line-height: 1}
.material-icons.md-36,
{ font-size: 36px; line-height: 1}
.material-icons.md-48,
{ font-size: 48px; line-height: 1}
