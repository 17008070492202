.carousel a {
  display: block;
  &:not(:first-child) {
    display: none;
  }
}
.slider-caption {
  position: absolute;
  
  margin-bottom: 0;
  color: #fff;
  max-width: inherit;
    a {
       color: #fff; 
    }
    .display-1 {
        font-family: $headings-font-family;
    }
    .caption-description {
        font-weight: 300;
        p {
            display: block;
            margin-bottom: 0;
        }
        img {
            display: inline-block;
            left: -35px;
            position: relative;
            margin-bottom: 1rem;
        }
    }
}

.slick-slide:not(.slick-current) .img-carousel.lazyload {
  display: none!important;
}
.slick-slide figure{
  position: absolute;
  top: 0;
}

@media (max-width:767px){
    .slider-caption {
        top: 3%;
        left: 60%;
        max-width: 40%;
        .display-1 {
            font-size: 1em;
        }
        .caption-description {
            font-size: 0.8em;
            img {
                max-width: 60px;
                height: auto;
                left: 0;
                margin-bottom: 0;
            }
        }
    }
}@media (min-width: 768px)and(max-width:991px){
    .slider-caption {
        top: 17%;
        left: 60%;
        .display-1 {
            font-size: 1.7em;
        }
        .caption-description {
            font-size: 1.100em;
            img {
                max-width: 100px;
                height: auto;
                left: -15px;
            }
        }
    }
}@media (min-width: 992px)and(max-width:1199px){
    .slider-caption {
        top: 17%;
        left: 60%;
        .display-1 {
            font-size: 1.7em;
        }
        .caption-description {
            font-size: 1.100em;
            img {
                max-width: 100px;
                height: auto;
                left: -15px;
            }
        }
    }
}@media (min-width: 1200px)and(max-width:1679px){
    .slider-caption {
        top: 20%;
        left: 60%;
        .display-1 {
            font-size: 2em;
        }
        .caption-description {
            font-size: 1.300em;
        }
    }
}@media (min-width: 1680px){
    .slider-caption {
        top: 30%;
        left: 60%;
        .display-1 {
            font-size: 3em;
        }
        .caption-description {
            font-size: 1.500em;
        }
    }
}

//.carousel {
//  box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
//  margin-bottom: 1.5rem;
//  .direction {
//    z-index: auto;
//  }
//  .carousel-inner {
//    height: 340px;
//    @media (max-width: 767px) {
//      height: auto;
//    }
//  }
//  .carousel-item {
//    height: 100%;
//    img {
//      @media (max-width: 767px) {
//        max-width: 100%;
//        height: auto;
//      }
//      @media (min-width: 768px) {
//      width: 100%;
//      margin-left: 0;
//    }
//    }
//    .caption {
//      position: absolute;
//      @media (min-width: 768px) {
//      bottom: 28px;
//      left: 90px;
//      }
//      @media (max-width: 767px) {
//        bottom: 5px;
//        left: 40px;
//      }
//      color: white;
//      max-width: 340px;
//      .caption-description p {
//        color: white;
//      }
//    }
//    figure {
//      @media (max-width: 767px) {
//        margin: 0;
//      }
//    }
//  }
//  .carousel-control {
//    opacity: 1;
//    .icon-next,
//    .icon-prev {
//      &::before {
//        content: "";
//      }
//      i {
//        font-size: 3.125rem;
//        color: white;
//      }
//      &:hover {
//        i {
//          color: $brand-primary;
//        }
//      }
//    }
//    .icon-prev {
//      left: 1rem;
//    }
//    .icon-next {
//      right: 2rem;
//    }
//    &.left,
//    &.right {
//      background: none;
//    }
//  }
//}
