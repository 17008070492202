.products{
  @extend .row;
  align-items:stretch;

}
.product-miniature{
  @extend .col-6;

}
@include media-breakpoint-desktop {
  .product-miniature{
    @include make-col(4);
  }

  .layout-full-width .product-miniature{
    @include make-col(3);
  }
}

@media (max-width: 320px) {
  .product-miniature{
    @include make-col(12);
  }
}

.slick-slide .product-miniature{
  max-width: 100%;
}
/* Product miniature*/
.card-product.card{
  height:100%;
  position: relative;
  background-color: $pm-bg-color;
  border-width: $pm-border-width;
  border-color: $pm-border-color;
    .regular-price {
        color: $secondary;
    }
    .price.current-price-discount {
        font-size: $product-price-regular-font-size;
    }
  &:hover
  {
    .highlighted-informations{
      transform:translateY(0);
    }

  }

  .discount{
    display:none;
  }
}

.card-img-top {
  overflow: hidden;
  .product-thumbnail img{
    width: 100%;
  }

}

.highlighted-informations{
  position: absolute;
  bottom: 0;
  width:100%;
  background-color: $pm-highlight-bg-color;
  transform:translateY(100%);
  transition:transform .3s;
  .card-product:hover & {
    transform:translateY($pm-highlight-translateY);
  }
}
.quick-view{
  cursor:pointer;
}

.product-flags{
  position:absolute;
  top:0;
  margin-bottom: 0;
  z-index: 1;
  left:-$spacer/4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.page-content--product .product-flags{
  left:0;
}
.product-flag{
  top:0;
  margin-top: $spacer/2;
  padding: $spacer/4 $spacer/2;
  color: $white;
  background: theme-color('primary');
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
    font-size: $small-font-size;
  &.discount-product{
    background: $tertiary;
  }
    &.out_of_stock {
        background: orange;    
    }
}
.color{
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
  border: 1px solid rgba(0,0,0,.05);
  cursor: pointer;
  background-size: contain;
}

.product-title{
  color: $pm-name-color;
  font-size: $pm-name-font-size;
  text-decoration: none;
  text-align: center;
  font-weight: $pm-name-font-weight;
  & a {
    color: $pm-name-color;
    &:hover{
      color: $pm-name-hover-color;
      border-bottom: $pm-name-hover-border-width solid $pm-name-hover-border-color;
    }

  }
  .card-product:hover & {
    color: $pm-name-hover-color;
    & a {
      color: $pm-name-hover-color;
    }
  }
}

/* PRODUCT PAGE */

.products-imagescover{
  box-shadow:$card-box-shadow;;
}
.product-img:not(:first-child) .rc {
  display: none;
}
.slick-initialized .product-img .rc{
  display: block;
}

.product-thumbs {
  width:$product-thumb-wrap-width;
  height: $product-thumb-wrap-height;
  margin:0 auto;
  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }
  &[data-count="3"]{
    width:$product-thumb-wrap-width-3;
  }
  .slick-slide > div{
    cursor: pointer;

  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }
    @media (max-width: 767px) {
        margin: 0 auto 2rem;    
    }
    @media (min-width: 768px)and(max-width: 1199px){
        width: 100% !important;
    }
    
    .slick-arrow.slick-prev, .slick-arrow.slick-next {
        text-indent: inherit !important;
    }
    
    @media (max-width: 1439px){
        .slick-arrow {
            top: 100%;
        }
        .slick-arrow.slick-prev {
            transform: translateX(-20px);
        }.slick-arrow.slick-next {
            transform: translateX(-20px);
        }
    }@media (min-width: 1440px){
        .slick-arrow.slick-prev {
            transform: translateX(-70px);
        }.slick-arrow.slick-next {
            transform: translateX(40px);
        }
    }
}
.product-thumb{
  margin: $product-thumb-margin;
  //padding:5px;
  //height:  auto;
  width:$product-thumb-width;
  height:$product-thumb-height;
  border:2px solid transparent;
  &.slick-current {
    border: 2px solid theme-color('primary');
  }
}
.btn-zoom{

  position: absolute;
  right: $spacer;
  bottom: $spacer;
  cursor: pointer;
  display: none;
  padding: 0;
  line-height: 1;
  & .material-icons{
  font-size: 3*$font-size-base;
  }
}
.images-container:hover .btn-zoom{
  display: block;
}
.label-color{
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item .color{
  width:2rem;
  height:2rem;
  border: 2px solid transparent;
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices
#product .product__product-price {
    width: 100%;
}
.current-price {
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-basis: 100%;
    font-size: 3rem;
}
.regular-price {
  color:$product-price-regular-color;
  font-size:$product-price-regular-font-size;
  font-weight:$product-price-regular-font-weight;
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
#product .product-prices {
    justify-content: space-between;
}
#product .product-price.has-discount {
    margin: 0 0 1rem;   
    display: flex;
    flex-direction: column;
}
.has-discount {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-basis: 100%;
    .current-price {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
    }
    .regular-price {
        font-size: 1.9rem;
    }
}
.price {
    color:$product-price-current-color;
    font-size:$product-price-current-font-size;
    font-weight:$product-price-current-font-weight;
    font-family: $product-price-current-font-family;
    &.current-price-discount {
        color:$product-price-discount-color;
        font-size: 2 * $font-size-base;
        font-weight:$product-price-discount-font-weight;
        font-family: $product-price-discount-font-family;
    }
}
.regular-price{
  color:$product-price-regular-color;
  font-size:$product-price-regular-font-size;
  font-weight:$product-price-regular-font-weight;
  font-family: $product-price-regular-font-family;
  text-decoration: line-through;
}
.discount{
  //text-transform: uppercase;
  color: $tertiary;
  //background-color: $product-price-discount-color;
  font-weight: 600;
  //display: inline-block;
  //padding: .25*$spacer;
    font-size: $product-price-discount-font-size;
    line-height: inherit;
    text-align: center;
    display: inline-block;
    width: 100%;
}
.tax-shipping-delivery-label {
    padding: 0 0 0 0.5rem; 
    font-size: 1.2rem;
    color: $product-price-current-color;
    &.tax-shipping-has-discount {
        color: $primary;
    }
}

//product add to cart
.btn-add-to-cart{
  //@extend .d-flex;
  text-transform: uppercase;
  font-weight: 600;
  white-space: nowrap;
}

//product tabs
.product-tabs{
  .nav-tabs{
    font-size: $font-size-base * .9;
  }
  //@extend .card;
  //@extend .card-block
  //box-shadow:$card-box-shadow;;
}
.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity{
  border-left:1px solid $border-color;
}
.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection{
  justify-content: space-between;
  align-items: center; 
}
.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature{
  margin-bottom: $spacer ;
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-bottom: 0;
  margin-top: $spacer;

}
.product-add-to-cart{
  .product-quantity{
      margin-top: -($spacer);
      }
    .qty{
      margin: 0 $spacer;
      margin-top: $spacer;

    }
    .add{
      margin-top: $spacer;

    }
}

.total-products {
    font-size: $small-font-size;
}
.product-customization{
  margin-bottom: $spacer * 1.5;
}
.product-discounts{
  margin-bottom: $spacer * 1.5;
}
.product__quantity{
  margin-bottom: 0;
}
.sort-by-row{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: $small-font-size;
}
.custom-select {
    font-size: $small-font-size; 
    box-shadow: none;
    border: 0;
    color: $secondary;
    &:focus {
        box-shadow: inset 0 1px 2px rgba(0,0,0,.075),0 0 0 .2rem $primary;
    }
}
.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.nav-tabs{
  justify-content: center;
}

#_desktop_category_header {
    h1 {
        &:after {
            display: none;
        }
    }
}

.card.card-product {
    border: 0;
}
.product__card-img {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
}
h2.product-title {
    font-size: $small-font-size; 
    margin-bottom: 0;
    padding: .75rem;
    &:after {
        display: none;
    }
}
.product-price-and-shipping {
    .price {
        font-size: $small-font-size; 
        color: $primary;    
    }
}