#product {
    h1 {
        text-align: left;
        padding: .75rem 0 1.5rem;
        &:after {
            display: none;
        }
    }
    
    .product-description-short {
        p {
            line-height: normal;
        }
    }
    .images-container {
        .product-img-inner, .rc {
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
            width: 96%;
            height: auto;
            margin: 2% 0 0 2%;
        }

    }
    
    .product__right {
        &__inner {
            background: $secondary;
            border-radius: 4px;
            padding: .5rem 1.5rem;    
        }
        
    }
    .product-prices {
        color: $primary;
        display: flex;
        justify-content: center;
        align-items: baseline;
        width: 100%;
    }
    .product-price {
        font-weight: 700;
        font-size: $h1-font-size;
        margin: 0 0 1rem 0;
        width: 100%;
    }
    #product-availability, .product-variants {
        color: $white;
    }
    #product-availability {
        display: block;
        margin-top: 1rem;
    }
    .product-add-to-cart {
        .product-quantity {
            .quantity__label {
                color: $white;
                margin: 1rem 0 0.5rem;
            }    
        } 
        .qty {
            margin: 0;
        }
        .input-group {
            background: $white;
            border-radius: 4px;
            justify-content: space-between;
            
            &.bootstrap-touchspin {
                border: 0;   
                box-shadow: none;
            }  
            @include media-breakpoint-desktop {
                height: calc(2em + .75rem + 2px);
            }
        }
        .input-group-btn {
            .btn {
                border-radius: 4px;
                padding: 0 1.5rem;
            }
        }
        .add {
            margin-top: 2rem;
        }
    }
    
    .product-manufacturer {
        .img-thumbnail {
            border: 0;
            padding: 0;
            box-shadow: none;
        } 
        @media (min-width: 500px)and(max-width: 767px){
            width: 60%;
        }@media (min-width: 768px)and(max-width: 1199px){
            width: 70%;
        }@media (min-width: 1200px){
            width: 50%;
        }
    }
    
    .blockreassurance_product {
        color: $white; 
        margin-top: 2rem;
        .col-xs-12 {
            padding: 0 0 1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .block-icon {
            margin: 0;
            &:before {
                font-size: 200%;
            }   
        }
        p.block-title {   
            font-weight: 700;
            font-size: $font-size-base * 0.9375;
            font-family: $headings-font-family;
            line-height: inherit !important;
            margin: 0 0 0 .5rem;
            vertical-align: super;
        }
        
        @media (max-width: 575px){
            p.block-title {
                font-size: $small-font-size;
            }
        }@media (min-width: 576px)and(max-width: 991px){
            p.block-title {
                font-size: 15px;
            }
        }
    }
    
    .product-information { 
        .product-description {
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    
    .product-tabs {
        .nav-tabs {
            justify-content: flex-start;
            border-bottom: 0;
            .nav-item {
                font-size: $h3-font-size;
                font-family: $font-size-base * 2;
                font-weight: 400;   
            }
            .nav-link {
                color: $primary;
                font-weight: 400;   
            }
        }
        .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
            color: $secondary;
        }    
    }
    
    .data-sheet {
        font-weight: 700;
        font-size: $font-size-base * 0.9;
        gap: 0 1rem;
        .value {
            color: $primary;    
        }
        li {
            border: 1px solid $secondary;
            border-radius: 4px;
            padding: .8rem 1rem;
            margin: .8rem 0;
        }
        @media (min-width: 768px){
            li {
                flex: 0 1 auto;
            }
            .name, .value {
                flex-basis: 100%;
            }    
        }
    }
}