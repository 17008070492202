.block-category {
    h1 {
        padding-left: 0;
    }
}

.block-categories {
  @extend .card;
}

.block-categories{
  @extend .card-body;
}

.block-categories {
  box-shadow:$card-box-shadow;;
}


.category-top-menu {
    font-weight: 700;
    text-transform: inherit;
    margin-bottom: 0;
    .category-top-menu-first {
        margin-bottom: 0.5rem;
    }
}


//block categories
.block-categories a{
  color: $body-color;
}
.category-sub__item {
    display: block;
    width: 100%;
    margin: $spacer/1.5 0;
    padding-left: $spacer/2;
    .category-sub-link {
        text-transform: inherit;
        font-weight: 400;
        font-size: $font-size-base * 0.9375;
    }
}
.category-sub__item--0{
  border-bottom: 0;
  padding: $spacer/1.5 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}

.navbar-toggler {
    cursor: pointer;
}

.page-category {
    .custom-select {
        height: inherit;
    }
    @media (max-width: 767px){
        .sort-by-row .form-inline {
            display: none;
        } 
        #categories_toggler {
            margin-bottom: 0.5rem;    
        }
        #search_filter_toggler {
            position: absolute;
            right: 1rem;
            bottom: 0.5rem;
        }
    }
}