html, body {
    overflow-x: hidden !important;
    //width: 100vw !important;
}

body{
    background-color: $wrapper-bg;
    font-size: $font-size-base;
    @media (max-width: 991px){
        font-size: $font-size-base * 0.85;   
    } 
}
a{
  text-decoration: none;
}
sup{
  top:-0.3em;
}

.l-wrapper{
  @if $enable-fluid-layout {
    @extend .container-fluid;
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }

}
.page-wrapper,.card-block{
  @extend .card;

}
.page-header{
  @extend .card-header;
  h1{
    margin-bottom: 0;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  text-transform: $h1-text-transform;
}

h1,.h1, h2,.h2, h3,.h3, h4,.h4, h5,.h5, h6,.h6 {
    padding: .75rem 0.75rem 1.5rem;
    font-weight: 600;
    text-align: center;
    position: relative;
    margin-bottom: 1rem;
    &:after {
        display: block;
        width: 150px;
        height: 1px;
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $primary;
    }
}
@media (max-width: 991px){
    h1,.h1 {
        font-size: $h1-font-size * 0.65; 
        margin-bottom: 0;
    }h2,.h2 {
        font-size: $h2-font-size * 0.65; 
        margin-bottom: 0;
    }h3,.h3 {
        font-size: $h3-font-size * 0.65; 
        margin-bottom: 0;
    }h4,.h4 {
        font-size: $h4-font-size * 0.65; 
        margin-bottom: 0;
    }h5,.h5 {
        font-size: $h5-font-size * 0.65; 
        margin-bottom: 0;
    }h6,.h6 {
        font-size: $h6-font-size * 0.65;
        margin-bottom: 0;
    }
}
.elementor-heading-title {
    padding: .75rem 0.75rem 1.5rem !important;
    margin-bottom: 2rem !important;
}

.quick-view{
  display: inline-block;
  @extend .small;
}

.form-control {
    height: inherit;
}

.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  box-shadow: $bs-touchspin-boxshadow;
  border:$input-border-width solid $input-border-color;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  background: rgba(0,0,0,0.05);
  transition: background 150ms;
  &:after{
    @extend .spinner-border;
    content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
      display: none;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;
}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-weight: bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  background-color: $gray-200;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-weight: $font-weight-bold;
}
.carrier-delay {
   font-size: $small-font-size; 
}
.carrier-price{
font-weight: bold;
font-size: $font-size-lg;
color: $primary;
}
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
  border: 3px solid $card-border-color;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-weight: 700;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: $section-title-margin-bottom;
  @extend .h1;
  font-family: $section-title-font-family;
  color:$section-title-color;
  font-size:$section-title-size;
  font-weight:$section-title-font-weight;
}

//home content spacing
.page-home > *{
  margin-bottom: $spacer-y;
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

nav {
    width: 100%;
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
.custom-select {
    border-radius: 4px;
    height: calc(2.5em + .75rem + 2px);
}
@include media-breakpoint-desktop{
    .page-content,
    .checkout-form,
    .page-wrapper--order-confirmation{
      form {
        width: 75%;
        //max-width: 450px;
        margin: 0 auto;
      }
    }
    .page-contact .page-content {
      form {
        width: 90%;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .page-wrapper--order-detail{
      form{
        margin: unset;
      }
    }
}
.form-footer{
  margin-top: $spacer;
}

// badge order history
.badge.bright{
  color:#fff;
}


// Buttons

.btn {
    font-weight: 700;
    background-color: transparent;
    border: 2px solid transparent;
    font-size: $small-font-size;
    padding: $small-font-size $h6-font-size;
    border-radius: $small-font-size * 2;
    transition: $transition;
    text-transform: uppercase;
    box-shadow: none;
    @include hover() {
        color: $body-color;
    }
    &.focus, &:focus {
        box-shadow: none;
    }
}
.btn-primary {
    background-color: $primary;
    border-color: $primary;
    &:hover {
        background-color: $white;
        color: $secondary;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show > &.dropdown-toggle:focus {
        background-color: $secondary;
        color: $primary;
        box-shadow: none; 
    }
}
.btn-secondary {
    background-color: $secondary;
    border-color: $secondary;
    color: $white;
    &:hover {
        background-color: $primary;
        border-color: $secondary;
        color: $white;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show > &.dropdown-toggle:focus {
        background-color: $primary;
        border-color: $secondary;
        color: $secondary;
        box-shadow: none;
    }
}
.btn-default {
    background-color: transparent;
    border-color: $primary;
    color: $primary;
    &:hover {
        background-color: $primary;
        border-color: $primary;
        color: $white;
    }
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus, &:not(:disabled):not(.disabled):active:focus, .show > &.dropdown-toggle:focus {
        background-color: $secondary;
        border-color: $primary;
        color: $primary;
        box-shadow: none;
    }
}
.btn-link {
    font-weight: $font-weight-normal;
    color: $link-color;
    text-decoration: $link-decoration;

    @include hover() {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    }

    &:focus,
    &.focus {
    text-decoration: $link-hover-decoration;
    }

    &:disabled,
    &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
    }
}
.btn-group-sm > .btn, .btn-sm {
    font-size: 14px;
    padding: $small-font-size / 1.75 $h6-font-size / 1.2;
}
.btn:not(:disabled):not(.disabled).active, .btn:not(:disabled):not(.disabled):active {
  box-shadow: none;
}




/**
 * BLOCK REASSURANCE COMMON FOOTER + PRODUCT
 */
.blockreassurance, .blockreassurance_product {
    .block-icon {
        font-family: 'icomoon';
    }
    .col-xs-12 {
        &:first-child {
            .block-icon:before {
                  content: "\e911";
            }
        }
        &:nth-child(2) {
            .block-icon:before {
              content: "\e910";
            }
        }&:nth-child(3) {
            .block-icon:before {
              content: "\e900";
            }
        }
        &:last-child{
            .block-icon:before {
              content: "\e904";
            }
        }    
    }
}

.blockreassurance {
    padding-top: 10px;
    text-align: center;
    width: 100%;
  .reass-item {
    display: inline-block;
    text-align: center;
    vertical-align: top;
  }
  .block-title {
    font-weight: bold;
  }
  .block-icon,
  .block-icon img,
  .block-icon svg {
    height: 70px;
  }
}

@media (max-width:575px) {
    .blockreassurance {
        text-align:left;
    }
    .blockreassurance .block-icon {
        display: inline-block;
        height: 35px;
        float: left;
        margin: 10px;
        width: 35px;
    }
    .blockreassurance .block-icon img,
    .blockreassurance .block-icon svg {
        height: 35px;
        width: 35px;
    }
    .blockreassurance .block-title {
        display: inline-block;
        height: 35px;
        max-width: calc(100% - 55px);
        padding-top: 10px;
    }
}

.blockreassurance_product {
    .item-product {
        display: inline-block;
        height: 35px;
        float: left;
        margin-right: 5px;
        padding-top: 5px;
        width: 35px;
    }
    .col-xs-12 {
        padding: 0 0 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .block-icon {
        margin: 0;
        &:before {
            font-size: 200%;
        }   
    }
    p.block-title {  
        font-weight: 700;
        font-size: $font-size-base * 0.9375;
        font-family: $headings-font-family;
        line-height: inherit !important;
        margin: 0 0 0 .5rem;
        vertical-align: super;
    }

    @media (max-width: 575px){
        p.block-title {
            font-size: $small-font-size;
        }
    }@media (min-width: 576px)and(max-width: 991px){
        p.block-title {
            font-size: 15px;
        }
    }
}

.blockreassurance_product .item-product img,
.blockreassurance_product .item-product svg {
    height: 35px;
    width: 35px;
}

.blockreassurance_product p.block-title {
    line-height: 40px;
}

/* Specific Checkout */
body#checkout .container-blockreassurance {
    min-height: auto;
}


/**
 * PAGINATION
 */

.page-link {
  color: $secondary;
    font-size: $small-font-size;
    border: 0;

  &:hover {
    z-index: 2;
    color: $pagination-hover-color;
    text-decoration: none;
    background-color: $pagination-hover-bg;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    outline: $pagination-focus-outline;
    box-shadow: $pagination-focus-box-shadow;
  }
}

.page-item {
  &:first-child {
    .page-link {
      margin-left: 0;
      @include border-left-radius($border-radius);
    }
  }
  &:last-child {
    .page-link {
      @include border-right-radius($border-radius);
    }
  }

  &.active .page-link {
    z-index: 3;
    color: $primary;
    background-color: transparent;
    border-color: transparent;
  }

  &.disabled .page-link {
    color: $pagination-disabled-color;
    pointer-events: none;
    // Opinionated: remove the "hand" cursor set previously for .page-link
    cursor: auto;
    background-color: $pagination-disabled-bg;
    border-color: $pagination-disabled-border-color;
  }
}

/**
 * PAGES MARQUES
 */
.page-manufacturer {
    .brands__list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        row-gap: 6rem;
    }
    .brand {
        flex: 1 1 20%;
        text-align: center;  
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
    } 
    @media(max-width:575px){
        .brands__list {
            row-gap: 1rem;
        }.brand {
            flex: 1 1 100%;
        }
    }@media(min-width: 576px)and(max-width:767px){
        .brands__list {
            row-gap: 3rem;
        }.brand {
            flex: 1 1 50%;
        }
    }@media(min-width: 768px)and(max-width:991px){
        .brands__list {
            row-gap:4rem;
        }.brand {
            flex: 1 1 33%;
        }
    }@media(min-width: 992px)and(max-width:1199px){
        .brands__list {
            row-gap: 4rem;
        }.brand {
            flex: 1 1 25%;
        }
    }
}
/*
@media (min-width: 992px) and (max-width: 1199px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .l-wrapper, .l-wrapper--boxed, main > .notifications-container {
        max-width: 950px;
        padding: 0;
    }
}@media (min-width: 1200px) and (max-width: 1439px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .l-wrapper, .l-wrapper--boxed, main > .notifications-container {
        max-width: 1140px;
        padding: 0;
    }
}@media (min-width: 1440px) and (max-width: 1680px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .l-wrapper, .l-wrapper--boxed, main > .notifications-container {
        max-width: 1400px;
        padding: 0;
    }
}*/