//color

$bg-dark :                      #dededd;
$tertiary:                      #f39d72;

//option
$enable-fluid-layout:            false;


/* HEADER */
$header-bg:                     $secondary;
$header-box-shadow:             null;
$header-nav-bg-color:           null;
$header-nav-color:              $white;
$header-nav-padding-y:          null;
$header-nav-border-color:       $border-color;
$header-top-border-color:       $header-nav-border-color;
/* MAIN */
$spacer-y : $spacer*2;
$wrapper-bg:                    #f1f1f1;
/* FOOTER */
$footer-bg:                     $white;
$footer-container-margin-top:   $spacer;

body {
  color: $secondary;
  @include font-size($font-size-base);
    background-color: $white !important;
}

.breadcrumb {
    background-color: transparent;
    padding: .75rem 0;
    font-size: $small-font-size;
}

.page-header {
    h1 {
        &:after {
            display: none;
        }
    }
}
.page-cms {
    .page-wrapper {
      background-color: transparent;
      border: 0 !important;
        padding: 0 !important;
    }

    .page-header {
        padding: 0 !important;
        background-color: transparent !important;
        border: 0 !important; 
        margin-bottom: 1rem !important;
        h1 {
            margin-bottom: 1rem !important;
            &:after {
                display: block;
            }
        }
    }

    .page-content {
        padding: 0;
    }
}


.checkout-process {
    .js-payment-ps_checkout-paypal,
    .js-payment-binary {
        display: none;
    }
    .alert-warning.accept-cgv {
        display: none !important;
        visibility: hidden;
    }
}
