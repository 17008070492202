.l-header, #header {
  background-color: $header-bg !important;
  margin-bottom: $spacer;
  box-shadow: $header-box-shadow;
    .u-link-body {
        color: $white;
    }
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: $spacer*1.25;
  padding-bottom: $spacer*1.25;
}

.header__search{
  flex: 1;
  padding: 0;
}
.header__right,
.header-nav__right{
    flex-grow: 1;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}

.header__TopNav {
    flex-grow: 1;
    a {
        color: $white;
        font-size: $small-font-size;
        &:hover, &:focus {
            color: $primary;
        }
        [class^="icon-"], [class*=" icon-"] {
            font-size: 150%;
            vertical-align: middle;
        }
    }
}

.customtext {
    .customtext__body {
        color: $white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        strong {
            font-size: $h4-font-size;
            text-transform: uppercase;
        }
    }
}
.header__search {
}
.shopping-cart {
    color: $white;
    .blockcart__label {
        display: inline-block;
        margin-bottom: 0;
    }
    .icon-basket {
        font-size: 120%;
        vertical-align: middle;
    }
    .blockcart__count {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: $primary;
        border-radius: 50%;
        font-size: $font-size-base * 0.6;
        font-weight: 700;
        text-align: center;
        position: relative;
        top: -10px;
        &:hover {
            background: transparent;
        }
    }
    .blockcart__label {
        &:hover {
            .blockcart__count {
                background: transparent;
            }
        }
    }
}


@include media-breakpoint-mobile{
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
      
  }
}
@media (max-width: 767px){
    .header__container {
        flex-direction: column;
    }
    .customtext {
        .customtext__body {
            justify-content: center;
            gap: 2rem;
            p {
                margin-bottom: 0.5rem;
                line-height: 1.4rem;
                &:first-child {
                    display: none;
                }
                &:not(:first-child) {
                    flex-grow: 1;
                }
            }
            strong {
                display: none;
            }
        }
    }
    .header-top__col{
        width: 120px;
        margin: 0 auto;
    }
    .header-nav {
        flex-grow: 1;
        .customtext {
            flex-grow: 1;
        }
    }
    .header__TopNav {
        width: 100%;
        a {
            [class^="icon-"], [class*=" icon-"] {
                display: block;
                text-align: left;
            }
        }
    }
    .shopping-cart {
        .icon-basket {
            display: block;
            text-align: left;
        }
    }
    .header__search {
        flex: 1 1 55%;
    }
    .header__right {
        margin-left: 2%;
    }
}@media (min-width: 768px)and(max-width: 991px) {
    .customtext {
        .customtext__body {
            p {
                margin-bottom: 0.5rem;
                line-height: 1.4rem;
                &:not(:first-child) {
                    flex-grow: 1;
                }
            }
            strong {
                display: none;
            }
        }
    }
    .header-top__col{
        width: 170px;
        margin-right: 4%;
    }
    .header-nav {
        flex-grow: 1;
        .customtext {
            flex-grow: 1;
        }
    }
    .header__TopNav {
        a {
            [class^="icon-"], [class*=" icon-"] {
                display: block;
                text-align: left;
            }
        }
    }
    .shopping-cart {
        .icon-basket {
            display: block;
            text-align: left;
        }
    }
    .header__search {
        flex: 0 1 55%;
    }
    .header__right {
        margin-left: 2%;
    }
}@media (min-width: 992px)and(max-width: 1199px) {
    .customtext {
        .customtext__body {
            flex-wrap: wrap;
            p {
                margin-bottom: 0.5rem;
                line-height: 1.4rem;
                &:first-child {
                    flex-basis: 100%;
                }
            }
            strong {
                font-size: $h5-font-size;
            }
        }
    }
    .header-top__col{
        width: 170px;
        margin-right: 4%;
    }
    .header-nav {
        flex-grow: 1;
        .customtext {
            flex-grow: 1;
        }
    }
    .header__search {
        flex: 0 1 60%;
    }
    .header__right {
        margin-left: 5%;
    }
}@media (min-width: 1200px)and(max-width: 1439px) {
    .customtext {
        .customtext__body {
            strong {
                font-size: $h5-font-size;
            }
        }
    }
    .header-top__col{
        width: 170px;
        margin-right: 2%;
    }
    .header-nav {
        flex-grow: 1;
        .customtext {
            flex-grow: 1;
        }
    }
    .header__TopNav {
        a {
            [class^="icon-"], [class*=" icon-"] {
                display: block;
                text-align: center;
            }
        }
    }
    .shopping-cart {
        .icon-basket {
            display: block;
            text-align: center;
        }
    }
    .header__search {
        flex: 0 1 70%;
    }
    .header__right {
        margin-left: 5%;
    }
}@media (min-width: 1440px) {
    .header-top__col{
        width: 170px;
        margin-right: 2%;
    }
    .header-nav {
        flex-grow: 1;
        .customtext {
            flex-grow: 1;
        }
    }
    .header__search {
        flex: 0 1 75%;
    }
    .header__right {
        margin-left: 2%;
    }
}


/**
 * MEGAMENU
 */
.ohm__megamenu {
    display: flex;
    flex-wrap: wrap;
    .fixpaddingsize2018 {
        padding: 0;
    }
    #mega_menu_plus, #mega_menu_plus .mg-menu {
        border: 0;
    }
    #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent > .sub {
        padding: 2rem;
    }
    #mega_menu_plus ul.mg-menu.menu-content > li {
        padding: 0;
    }
    #mega_menu_plus ul.mg-menu > li > a {
        padding: 0;
    }
    #mega_menu_plus ul.mg-menu.menu-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    #mega_menu_plus .menu-item-link-text {
        font-family: $font-family-sans-serif;
        text-transform: uppercase;
        font-weight: 700;
    }
    #mega_menu_plus li.submenu > a > span::after,
    #mega_menu_plus li.submenu:hover > a > span::after {
        font-family: 'icomoon' !important;
        content: '\e903';
    }

    #mega_menu_plus ul.mg-menu > li ul li a, #mega_menu_plus ul > li > a, #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent > .sub .titlesub .menu-item-link-text,
    #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent > .sub .submenu-container ul > li > a {
        font-size: $font-size-base !important;
        text-transform: uppercase;
        font-weight: 700;
        color: $secondary;
        @media(max-width:1199px){
            font-size: $small-font-size !important;
        }
    }
    #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent > .sub .submenu-container ul > li > a,
    .content_submenu ul.menulink li a {
        color: $secondary !important;
    }
    #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent:hover > a, #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent:hover > a:hover,
    #mega_menu_plus ul.mg-menu.menu-content li.mainmenu-parent > .sub .submenu-container ul > li > a:hover, #mega_menu_plus ul.treelinks li ul li:hover > a {
        color: $primary !important;
    }
    .mg-menu > li.submenu:hover > a::before {
        border-color: transparent;
    }
    #mega_menu_plus ul li div.sub {
        border: 1px solid $secondary;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    ul.treelinks li ul li ul {
        box-shadow: 0;
    }
    ul.treelinks li a {
        border: 0;
    }
    #mega_menu_plus .sub .rows .cols {
        float: none;
        p {
            margin-bottom: 0;
        }
    }
    .cols .content_submenu {
        padding-left: 0;
        float: none;
    }
    #mega_menu_plus .sub .rows {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 0;
        gap: 1rem;
    }
    
    .cols .content_submenu .titlesub {
        border-bottom: 0;
        margin-bottom: 0;
        @media (min-width: 769px){
            padding-top: 0 !important;
        }
    } 
    
    .submenu  {
        .titlesub {
            
        }  
    }
    
    .bamenuitem-jedebute, .bamenuitem-ecigarettes {
        .row_0 {
            .cols {
                margin-bottom: 1rem;
                @media(max-width: 991px){
                    flex-basis: 48%;
                    &.col_0 {
                      order: 1;
                    }&.col_1 {
                      order: 3;
                    }&.col_2 {
                      order: 5;
                    }&.col_3 {
                      order: 2;
                    }&.col_4 {
                      order: 4;
                    }&.col_5 {
                      order: 6;
                    }
                }@media(min-width: 992px){
                    flex-basis: 32%;
                }@media(min-width: 1200px)and(max-width: 1439px){
                    .img-fluid {width: 70%;
                        height: auto;
                    }
                }@media(min-width: 1440px){
                    .img-fluid {width: 60%;
                        height: auto;
                    }
                }
            }    
        }
    }.bamenuitem-ecigarettes {
        .row_1 {
            margin-top: 2rem;
            @media(min-width: 992px){
                flex-wrap: nowrap;
            }
            .cols {
                margin-bottom: 1rem;
                @media(max-width: 991px){
                    flex-basis: 48%;
                    &.col_0 {
                      order: 1;
                    }&.col_1 {
                      order: 3;
                    }&.col_2 {
                      order: 5;
                    }&.col_3 {
                      order: 7;
                    }&.col_4 {
                      order: 2;
                    }&.col_5 {
                      order: 4;
                    }&.col_6 {
                      order: 6;
                    }&.col_7 {
                      order: 8;
                    }
                }
                @media(min-width: 992px){
                    flex-basis: 23%;
                }@media(min-width: 1200px)and(max-width: 1439px){
                    .img-fluid {width: 70%;
                        height: auto;
                    }
                }@media(min-width: 1440px){
                    .img-fluid {width: 60%;
                        height: auto;
                    }
                }
            }    
        }
    } 
    
    .bamenuitem-menu-materiel {
        /*.submenu-level-0_3 {
            width: 100% !important;
            left: 0;
        }
        .submenu-container .treelinks .children_2 > ul {
            display:flex;
            justify-content: space-between;
        }
        .submenu-container .treelinks .children_2 .children_3 > ul {
            display: block;
            position: relative;
            box-shadow: none;
            left: 0;
            li.endli {
                .menu-item-link-text {
                    font-weight: 400 !important;
                }
            }
        }
        .submenu-container .treelinks .children_2 .children_3 a:after {
            display: none;
        }*/
        .sub .rows {
            flex-wrap: nowrap !important;
            &.row_1 {
                justify-content: flex-start !important;
                margin-top: 2rem;
            }
            @media (max-width: 1080px){  
                flex-wrap: wrap !important;
            }
        }
        .menulink {
            .menu-item-link-text {
                font-weight: 400 !important;
                line-height: 30px !important;
            }
        }
    }
    .bamenuitem-menu-eliquides {  
        .sub .rows {
            flex-wrap: nowrap !important;
            &.row_1 {
                //justify-content: flex-start !important;
                margin-top: 2rem;
            }
            @media (max-width: 1080px){  
                flex-wrap: wrap !important;
            }
        }
        .menulink {
            .menu-item-link-text {
                font-weight: 400 !important;
                line-height: 30px !important;
            }
        }  
    }
    .bamenuitem-menu-diy {  
        /*.hidesubmenu2018 {
            display: block;
        }*/
        .submenu-container {
            display: flex;
            flex-wrap: wrap;
        }
        .sub .rows {
            flex-wrap: nowrap !important;
            &.row_1 {
                //justify-content: flex-start !important;
                order: 3;
            }
            &.row_2 {
                margin-top: 2rem;
                order: 2;
            }
            @media (max-width: 1080px){  
                flex-wrap: wrap !important;
            }
        }
        .col_0,
        .col_1 {
            .menulink {
                .menu-item-link-text {
                    font-weight: 700 !important;
                    line-height: 30px !important;
                }
            }
        }
        .menulink {
            .menu-item-link-text {
                font-weight: 400 !important;
                line-height: 30px !important;
            }
        }  
    }
    
    @media (max-width: 1080px){   
        #mega_menu_plus ul.mg-menu.menu-content {
            display: block;    
        }  
        
        .bamenuitem-menu-materiel,
        .bamenuitem-menu-eliquides,
        .bamenuitem-menu-diy,
        .bamenuitem-menu-bonsplans {
            #mega_menu_plus .sub .rows {
                display: block;
            }
        }
        .menulink li {
            margin: 0.5rem 0;
        }
        
        .bamenuitem-menu-materiel .submenu-container .treelinks .children_2 > ul {
            display: block;
            .submore {
                display: none;
            }
        }
    }
}


//
//.header-nav{
//  border-bottom: 1px solid $header-nav-border-color;
//  background-color: $header-nav-bg-color;
//  color:$header-nav-color;
//  padding-top: $header-nav-padding-y;
//  padding-bottom: $header-nav-padding-y;
//  & a:not(.dropdown-item), .btn{
//    color: $header-nav-color;
//  }
//}
////.header-top{
////  border-bottom: 1px solid $header-top-border-color;
////}
//@include media-breakpoint-mobile {
//  .header__container{
//    padding-left: 0;
//    padding-right: 0;
//  }
//  .logo{
//    width:100%;
//    max-width: 115px;
//  }
//}
