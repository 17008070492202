.slick-arrow {
    position: absolute;
    top:0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0;
    z-index: 1;
}
    
.slick-arrow.slick-prev, .slick-arrow.slick-next,
.elementor-slick-slider .slick-prev,
.elementor-slick-slider .slick-next {
    display: block !important;
    border: 0 !important;
    width: 30px !important;
    height: 30px !important;
    text-indent: -9999px !important;
    @media (max-width: 767px){
        width: 20px !important;
        height: 20px !important; 
    }@media (min-width: 768px)and(max-width:991px){
        width: 25px !important;
        height: 25px !important; 
    }
}

.elementor {
    .slick-arrow.slick-prev, .elementor-slick-slider .slick-prev {
        background: url(../img/left-arrow.svg) no-repeat center center transparent !important;
        background-size: cover !important; 
        left: -40px !important;
    }.slick-arrow.slick-next, .elementor-slick-slider .slick-next {
        background: url(../img/right-arrow.svg) no-repeat center center transparent !important;
        background-size: cover !important; 
        right: -40px !important;
    }
    .slick-dots {
        text-align: center;
        width: 100%;
        margin-top: 20px;
        li {
            padding: 1rem !important;
            button {
                border: 0;
                width: 10px !important;
                height: 10px !important;
                border-radius: 50%;
                background: $primary !important;   
                text-indent: -9999px;
            }
        }
        .slick-active {
            button {
                background: $secondary !important;
            }
        }
    }
}

.slick-prev {
    left:0;
}
.slick-next{
    right:0;
}
.slick-slide figure{
    margin: 0;
}
.slick__arrow-outside {
    .slick-prev {
        transform:translateX(-50px);
    }
    .slick-next{
        transform:translateX(50px);
    }
}

/* carousel des marques */
.carousel {
    margin-bottom: 3rem;
    &.slick__arrow-large {
        .slick-arrow i{
            font-size: 3*$font-size-base;
            text-shadow: none;
            color: $white;
        }
    }
    .slick-dots {
        text-align: center;
        width: 100%;
        margin-top: 20px;
        li {
            display: inline-block;
            padding: .8rem;
            button {
                border: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $primary;   
                text-indent: -9999px;
            }
        }
        .slick-active {
            button {
                background: $secondary;
            }
        }
    }
}